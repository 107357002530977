<template>
  <div class="section">
    <div class="container">
      <div class="row row-split-content">
        <div class="content-width-small" style="text-align: center;">
          <h6 class="subheading text-primary-1">Congrats you now have access to</h6>
          <h3 class="h1">Scheduled Posting!</h3>
          <div style="text-align: justify;">Watch this video and connect your social accounts.</div>
          <a v-if="!ready" href="#" class="button w-inline-block">
            <div>
              <button class="button w-inline-block prep" disabled>
                <div>
                  Preparing
                  <i class="fas fa-sync fa-spin"></i>
                </div>
              </button>
            </div>
          </a>
          <a v-else :href="automationLink" class="button w-inline-block">
            <div>
              <a class="button w-inline-block">
                <div>
                  Connect your social accounts
                  <i class="fas sign-in-alt"></i>
                </div>
              </a>
            </div>
          </a>
        </div>
        <div class="content-width-large">
          <div data-animation="slide" data-duration="500" data-infinite="1" class="slider w-slider">
            <div class="w-slider-mask">
              <div class="w-slide">
                <p>*Please watch this training video before proceeding.</p>
                <iframe
                  src="https://portal.midweststreams.com/services/iframe/11519"
                  scrolling="no"
                  width="648"
                  height="400"
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  style="margin-left:auto; margin-right:auto; display:block;"
                ></iframe>
              </div>
            </div>

            <div class="display-none w-slider-nav w-round"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from "@/services/profileService.js";
export default {
  data() {
    return {
      polling: null,
      ready: false
    };
  },
  computed: {
    automationLink: function() {
      if (
        this.$auth.user["https://theagentnest.com/scheduled_sending"] != true
      ) {
        return "/upgrade";
      } else {
        var hmac = this.$auth.user["https://theagentnest.com/hmac"];
        var safeEmail = encodeURIComponent(this.$auth.user.email);
        var baseUrl = process.env.VUE_APP_API_URL;
        return (
          baseUrl +
          "/api/user/schedule-redirect?email=" +
          safeEmail +
          "&hmac=" +
          hmac +
          "&path=services"
        );
      }
    }
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        const { user } = this.$auth || {};
        const { sub, email } = user || {};
        var userId = sub;
        this.email = email;
        // eslint-disable-next-line no-undef
        mixpanel.identify(userId);
        var vm = this;
        this.$auth.getTokenSilently().then(function(result) {
          vm.bearer = result;
          ProfileService.getUserInfo(userId, result)
            .then(response => {
              var status = response.data.app_metadata.status;

              if (status == "current") {
                vm.ready = true;
              } else {
                vm.ready = false;
              }
            })
            .catch(error => {
              console.log(
                "there was an error getting the user metadata",
                error
              );
            });
        });
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    this.pollData();
    var today = new Date().toISOString();

    // eslint-disable-next-line no-undef
    mixpanel.track("Sign Up", { Date: today });
  }
};
</script>

<style scoped>
.prep {
  cursor: not-allowed;
}
</style>